<template>
  <textarea placeholder='输入pac' v-on:input="onContentChange($event.target.value)"></textarea>
</template>

<script>
const {PacCheck} = require('/pkg')

export default {
  name: 'PacInput',
  props: {
    pc: PacCheck,
  },
  emits: [
    'parse-ok',
    'parse-error'
  ],
  methods: {
    onContentChange(newContent) {
      try {
        this.pc.parse(newContent)
        this.$emit('parse-ok')
      } catch (e) {
        this.$emit('parse-error', e.stack)
      }
    }
  }
}
</script>

<style scoped>

</style>