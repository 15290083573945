<template>
  <input placeholder="输入域名或ip" v-on:input="on_input($event.target.value)" />
</template>

<script>
import PacInput from "@/components/PacInput";

export default {
  name: "HostInput",
  props: {
    pc: PacInput,
  },
  emits: [
      'check-ok',
      'check-error',
  ],
  methods: {
    on_input(text) {
      try {
        let match = this.pc.check(text)
        this.$emit('check-ok', match)
      } catch (e) {
        this.$emit('check-error', e.stack)
      }
    }
  }
}
</script>

<style scoped>

</style>